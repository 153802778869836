import $ from 'jquery';
import 'slick-carousel';
import { addInfiniteOnEdge } from 'general/js/carouselService';
import { sliderForTouchScreens } from 'general/js/carouselService';

(() => {
    if (!Array.from) {
        Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
                return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
                var number = Number(value);
                if (isNaN(number)) {
                    return 0;
                }
                if (number === 0 || !isFinite(number)) {
                    return number;
                }
                return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
                var len = toInteger(value);
                return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike /*, mapFn, thisArg */) {
                // 1. Let C be the this value.
                var C = this;

                // 2. Let items be ToObject(arrayLike).
                var items = Object(arrayLike);

                // 3. ReturnIfAbrupt(items).
                if (arrayLike == null) {
                    throw new TypeError(
                        'Array.from requires an array-like object - not null or undefined'
                    );
                }

                // 4. If mapfn is undefined, then let mapping be false.
                var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                var T;
                if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                        throw new TypeError(
                            'Array.from: when provided, the second argument must be a function'
                        );
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                        T = arguments[2];
                    }
                }

                // 10. Let lenValue be Get(items, "length").
                // 11. Let len be ToLength(lenValue).
                var len = toLength(items.length);

                // 13. If IsConstructor(C) is true, then
                // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
                // 14. a. Else, Let A be ArrayCreate(len).
                var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                // 16. Let k be 0.
                var k = 0;
                // 17. Repeat, while k < len… (also steps a - h)
                var kValue;
                while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                        A[k] =
                            typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                        A[k] = kValue;
                    }
                    k += 1;
                }
                // 18. Let putStatus be Put(A, "length", len, true).
                A.length = len;
                // 20. Return A.
                return A;
            };
        })();
    }




})();

(function() {

    var slickElement = $(".range-carousel-section__slider");
    slickElement.slick({
        infinite: true,
        centerMode: true,
        dots: false,
        speed: 500,
        cssEase: 'ease-in',
        useTransform: false,
        arrows: true,
        centerPadding: 0,
        prevArrow: $('.range-carousel-section__arrows-section__left'),
        nextArrow: $('.range-carousel-section__arrows-section__right'),
        variableWidth: false,
    });

        sliderForTouchScreens(slickElement);
    })();


$('.range__carousel-mobile-image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: false,
    infinite: true,
    asNavFor: '.range__carousel-mobile-content',
    customPaging: function (slider, i) {
        var thumb = $(slider.$slides[i]).data();
        var className = $(slider.$slides[i]).find('.js-class-image').data().class;
        return '<span class="' + className + '">0' + (i + 1) + '</span>';
    },
});
let colors = [];
$('.range__carousel-mobile-content').on('init', function (event, slick) {
    colors = Array.from(slick.$slider[0].getElementsByClassName('slide')).map(
        (s) => s.style.backgroundColor
    );
    slick.$slider.css({ backgroundColor: colors[0] });
    Array.from(slick.$slider[0].getElementsByClassName('slide')).forEach((el) => {
        el.style.removeProperty('background-color');
    });
});

$('.range__carousel-mobile-content').slick({
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '180px',
    infinite: true,
    dots: false,
    speed: 500,
    arrows: true,
    asNavFor: '.range__carousel-mobile-image',
    prevArrow: $('.left-arrow'),
    nextArrow: $('.right-arrow'),
    responsive: [
        {
            breakpoint: 720,
            settings: {
                centerPadding: '80px',
            },
        },
        {
            breakpoint: 370,
            settings: {
                arrows: false,
                prevArrow: '',
                nextArrow: '',
                centerMode: false,
            },
        },
    ],
});

$('.range__carousel-mobile-content').on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
        $(slick.$slides[nextSlide]).css({
            opacity: 1,
            right: '',
        });
        slick.$slider.css({ backgroundColor: colors[nextSlide] });
    }
);

$('.range__carousel-mobile-content').on('afterChange', function (event, slick, currentSlide) {
    $(slick.$slides[currentSlide - 1]).css({
        opacity: 0,
    });
});

addInfiniteOnEdge('.range__carousel-mobile-content');
